<template>
    <v-container
        id="dashboard"
        fluid
        tag="section"
    >
        <v-row justify="center">
            <v-col
                cols="12"
                lg="9"
                xl="7"
                class="block-media-lk"
            >
                <div class="text-center mt-10">
                    <v-btn color="success" :to="{name: 'PayInstruments'}">Доступ к инструментам</v-btn>
                </div>
                <v-expansion-panels
                    popout
                    focusable
                    class="exp-panel-title mt-10"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <span>
                                <v-icon class="mr-2" color="red">mdi-numeric-1-box-multiple</v-icon>
                                С чего начать?
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p class="mt-7">
                                1. Добавься в чат поддержки в телеграме для того, чтобы не пропустить новости и обновления платформы
                                <v-icon class="mr-2" color="blue">mdi-arrow-down-bold-outline</v-icon>
                            </p>
                            <p><a href="https://t.me/+rHS29bZRITtlZDRi" target="_blank">Чат поддержки newlvl.net</a></p>

                            <p class="mt-5">
                                2. Посмотри видео "Обзор платформы newlvl.net"
                                <v-icon class="mr-2" color="blue">mdi-arrow-down-bold-outline</v-icon>
                            </p>
                            <div class="thumb-wrap">
                                <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/7Uj5rtUi-q8"
                                    title="Обзор платформы newlvl"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                >
                                </iframe>
                            </div>

                            <v-expansion-panels
                                popout
                                focusable
                                class="exp-panel-title mt-10"
                            >
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="red">mdi-alert-circle</v-icon>
                                            Если видео не грузится
                                        </span>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p class="mt-10">
                                            Если на вашем устройстве не загружается видео с youtube, то вы можете скачать и посмотреть
                                            видео-инструкцию с гугл-диска:
                                            <a href="https://drive.google.com/drive/folders/1xNr_szC3_LhYkknkP6V5jSRGk2Vk7Kdu?usp=sharing">Видео-инструкции</a>
                                        </p>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>

                            <p class="mt-10">
                                <v-icon class="mr-2" color="red">mdi-alert-outline</v-icon>
                                Добавили новую вкладку "Лайфхаки". В ней будут полезные рекомендации.
                                <v-icon class="mr-2" color="blue">mdi-arrow-down-bold-outline</v-icon>
                            </p>
                            <p style="width: 100%; max-width: 600px; height: auto">
                                <img style="width: 100%" src="/img/imgLifehack.png" alt=""/>
                            </p>

                            <p class="mt-10">
                                <v-icon class="mr-2" color="red">mdi-alert-outline</v-icon>
                                Если вы планируете размещать реферальные ссылки в VK, то обязательно посмотрите видео
                                "Рекомендация по размещению ссылок на инструменты newlvl в VK", которое находится во вкладке "Лайфхаки".
                            </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <span>
                                <v-icon class="mr-2" color="pink darken-3">mdi-numeric-2-box-multiple</v-icon>
                                Ознакомься с правилами
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <h3 class="mt-5 mb-5">
                                <v-icon class="mr-2" color="blue">mdi-alert-outline</v-icon>
                                Правила пользования инструментами и сервисом newlvl
                            </h3>
                            <p>
                                1. newlvl не несет ответственность за действия пользователей на сторонних ресурсах
                            </p>
                            <p>
                                2. Вся информация, рекомендации, видео, инструменты, методички, руководства и прочие материалы, предоставленные пользователям
                                на сайте newlvl, носят рекомендательный характер
                            </p>
                            <p>
                                3. Ответственность по размещению реферальных ссылок на сторонних ресурсах, в социальных сетях и т.п.:
                            </p>
                            <ul class="mb-5">
                                <li class="mb-3">
                                    Пользователь несет полную ответственность за размещение персональных реферальных ссылок на сторонних ресурсах, в социальных сетях и т.п.
                                </li>
                                <li>
                                    По окончании срока действия оплаченного пакета услуг пользователь должен самостоятельно удалить свои персональные
                                    реферальные ссылки везде, где он их продвигал или указывал
                                </li>
                            </ul>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <span>
                                <v-icon class="mr-2" color="orange">mdi-numeric-3-box-multiple</v-icon>
                                Настройка профиля
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p class="mt-7">
                                <v-icon class="mr-2 mt-n1" color="red">mdi-alert-circle</v-icon>
                                Прежде чем приступить к работе с инструментами, правильно заполни свой "Профиль". Для этого посмотри видео "Настройка профиля"
                                <v-icon class="mr-2" color="blue">mdi-arrow-down-bold-outline</v-icon>
                            </p>
                            <div class="thumb-wrap">
                                <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/5cI5LzhMITA"
                                    title="Основные настройки профиля партнера"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                >
                                </iframe>
                            </div>

                            <v-expansion-panels
                                popout
                                focusable
                                class="exp-panel-title mt-10"
                            >
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="red">mdi-alert-circle</v-icon>
                                            Если видео не грузится
                                        </span>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p class="mt-10">
                                            Если на вашем устройстве не загружается видео с youtube, то вы можете скачать и посмотреть
                                            видео-инструкцию с гугл-диска:
                                            <a href="https://drive.google.com/drive/folders/1xNr_szC3_LhYkknkP6V5jSRGk2Vk7Kdu?usp=sharing">Видео-инструкции</a>
                                        </p>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <span>
                                <v-icon class="mr-2" color="pink">mdi-numeric-4-box-multiple</v-icon>
                                Настройка страницы-визитки
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <h3 class="mt-5 mb-5">
                                Перейди по ссылке и настрой страницу-визитку
                            </h3>
                            <p>
                                <v-icon class="mr-2" color="blue">mdi-arrow-right-bold-outline</v-icon>
                                <router-link
                                    color="success"
                                    :to="{name: 'VizitkaInstrum'}"
                                >Страница-визитка</router-link>
                            </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header>
                            <span>
                                <v-icon class="mr-2" color="green">mdi-numeric-5-box-multiple</v-icon>
                                Обзор инструментов
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="exp-in-dashboard">  
                          <v-expansion-panels
                              popout
                              focusable
                              class="exp-panel-title mt-5 mb-5"
                          >
                              <v-expansion-panel>
                                  <v-expansion-panel-header>
                                      <span>
                                          <v-icon class="mr-2" color="blue">mdi-bookmark-multiple-outline</v-icon>
                                          Страница-визитка
                                      </span>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                      <p class="mt-7">
                                          Видео-обзор страницы-визитки
                                      </p>
                                      <div class="thumb-wrap">
                                          <iframe
                                              width="560"
                                              height="315"
                                              src="https://www.youtube.com/embed/WoRdqPr9Dq0"
                                              title="Обзор Страницы-визитки"
                                              frameborder="0"
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                              allowfullscreen
                                          >
                                          </iframe>
                                      </div>
                                      <v-expansion-panels
                                          popout
                                          focusable
                                          class="exp-panel-title mt-10"
                                      >
                                          <v-expansion-panel>
                                              <v-expansion-panel-header>
                                          <span>
                                              <v-icon class="mr-2" color="red">mdi-alert-circle</v-icon>
                                              Если видео не грузится
                                          </span>
                                              </v-expansion-panel-header>
                                              <v-expansion-panel-content>
                                                  <p class="mt-10">
                                                      Если на вашем устройстве не загружается видео с youtube, то вы можете скачать и посмотреть
                                                      видео-инструкцию с гугл-диска:
                                                      <a href="https://drive.google.com/drive/folders/1xNr_szC3_LhYkknkP6V5jSRGk2Vk7Kdu?usp=sharing">Видео-инструкции</a>
                                                  </p>
                                              </v-expansion-panel-content>
                                          </v-expansion-panel>
                                      </v-expansion-panels>
                                      <p class="mt-10">
                                          Посмотреть
                                          <a href="https://newlvl.net/vizitka/?partner=000" target="_blank">
                                              пример страницы-визитки
                                          </a>
                                      </p>
                                      <p class="p-price">Доступ: <span>Бесплатно</span></p>
                                  </v-expansion-panel-content>
                              </v-expansion-panel>

                              <v-expansion-panel>
                                  <v-expansion-panel-header>
                                      <span>
                                          <v-icon class="mr-2" color="purple">mdi-bookmark-multiple-outline</v-icon>
                                          Тест по здоровью
                                      </span>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                      <p class="mt-7">
                                          Видео-обзор онлайн-теста по здоровью
                                      </p>
                                      <div class="thumb-wrap">
                                          <iframe
                                              width="560"
                                              height="315"
                                              src="https://www.youtube.com/embed/9kg9znFymLw"
                                              title="Обзор онлайн-теста по здоровью"
                                              frameborder="0"
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                              allowfullscreen
                                          >
                                          </iframe>
                                      </div>
                                      <v-expansion-panels
                                          popout
                                          focusable
                                          class="exp-panel-title mt-10"
                                      >
                                          <v-expansion-panel>
                                              <v-expansion-panel-header>
                                          <span>
                                              <v-icon class="mr-2" color="red">mdi-alert-circle</v-icon>
                                              Если видео не грузится
                                          </span>
                                              </v-expansion-panel-header>
                                              <v-expansion-panel-content>
                                                  <p class="mt-10">
                                                      Если на вашем устройстве не загружается видео с youtube, то вы можете скачать и посмотреть
                                                      видео-инструкцию с гугл-диска:
                                                      <a href="https://drive.google.com/drive/folders/1xNr_szC3_LhYkknkP6V5jSRGk2Vk7Kdu?usp=sharing">Видео-инструкции</a>
                                                  </p>
                                              </v-expansion-panel-content>
                                          </v-expansion-panel>
                                      </v-expansion-panels>
                                      <p class="mt-10">
                                          Посмотреть
                                          <a href="https://newlvl.net/health-test/?inst=test&partner=000" target="_blank">
                                              пример теста по здоровью
                                          </a>
                                      </p>
                                      <p>
                                          Как получить доступ?
                                          <router-link
                                              :to="{name: 'PayInstruments'}"
                                              style="text-decoration: underline"
                                          >
                                              Подробнее
                                          </router-link>
                                      </p>
                                  </v-expansion-panel-content>
                              </v-expansion-panel>

                              <v-expansion-panel>
                                  <v-expansion-panel-header>
                                      <span>
                                          <v-icon class="mr-2" color="indigo">mdi-bookmark-multiple-outline</v-icon>
                                          Тест по бизнесу
                                      </span>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                      <p class="mt-7">
                                          Видео-обзор онлайн-теста по бизнесу
                                      </p>
                                      <div class="thumb-wrap">
                                          <iframe
                                              width="560"
                                              height="315"
                                              src="https://www.youtube.com/embed/jPNwACg1aVQ"
                                              title="Обзор онлайн-теста по бизнесу"
                                              frameborder="0"
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                              allowfullscreen
                                          >
                                          </iframe>
                                      </div>
                                      <v-expansion-panels
                                          popout
                                          focusable
                                          class="exp-panel-title mt-10"
                                      >
                                          <v-expansion-panel>
                                              <v-expansion-panel-header>
                                          <span>
                                              <v-icon class="mr-2" color="red">mdi-alert-circle</v-icon>
                                              Если видео не грузится
                                          </span>
                                              </v-expansion-panel-header>
                                              <v-expansion-panel-content>
                                                  <p class="mt-10">
                                                      Если на вашем устройстве не загружается видео с youtube, то вы можете скачать и посмотреть
                                                      видео-инструкцию с гугл-диска:
                                                      <a href="https://drive.google.com/drive/folders/1xNr_szC3_LhYkknkP6V5jSRGk2Vk7Kdu?usp=sharing">Видео-инструкции</a>
                                                  </p>
                                              </v-expansion-panel-content>
                                          </v-expansion-panel>
                                      </v-expansion-panels>
                                      <p class="mt-10">
                                          Посмотри пример теста по бизнесу
                                          <a href="https://newlvl.net/biz-test/?inst=biztest&partner=000" target="_blank">
                                              пример теста по бизнесу
                                          </a>
                                      </p>
                                      <p>
                                          Как получить доступ?
                                          <router-link
                                              :to="{name: 'PayInstruments'}"
                                              style="text-decoration: underline"
                                          >
                                              Подробнее
                                          </router-link>
                                      </p>
                                  </v-expansion-panel-content>
                              </v-expansion-panel>

                              <v-expansion-panel>
                                  <v-expansion-panel-header>
                                      <span>
                                          <v-icon class="mr-2" color="orange">mdi-bookmark-multiple-outline</v-icon>
                                          Бизнес-презентация
                                      </span>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                      <p class="mt-7">
                                          Видео-обзор бизнес-презентации
                                      </p>
                                      <div class="thumb-wrap">
                                          <iframe
                                              width="560"
                                              height="315"
                                              src="https://www.youtube.com/embed/Qn4d8P_IblA"
                                              title="Обзор Бизнес-презентации"
                                              frameborder="0"
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                              allowfullscreen
                                          >
                                          </iframe>
                                      </div>
                                      <v-expansion-panels
                                          popout
                                          focusable
                                          class="exp-panel-title mt-10"
                                      >
                                          <v-expansion-panel>
                                              <v-expansion-panel-header>
                                          <span>
                                              <v-icon class="mr-2" color="red">mdi-alert-circle</v-icon>
                                              Если видео не грузится
                                          </span>
                                              </v-expansion-panel-header>
                                              <v-expansion-panel-content>
                                                  <p class="mt-10">
                                                      Если на вашем устройстве не загружается видео с youtube, то вы можете скачать и посмотреть
                                                      видео-инструкцию с гугл-диска:
                                                      <a href="https://drive.google.com/drive/folders/1xNr_szC3_LhYkknkP6V5jSRGk2Vk7Kdu?usp=sharing">Видео-инструкции</a>
                                                  </p>
                                              </v-expansion-panel-content>
                                          </v-expansion-panel>
                                      </v-expansion-panels>
                                      <p class="mt-10">
                                          Посмотреть
                                          <a href="https://newlvl.net/biz/?inst=biz&partner=000" target="_blank">
                                              пример бизнес-презентации
                                          </a>
                                      </p>
                                      <p>
                                          Как получить доступ?
                                          <router-link
                                              :to="{name: 'PayInstruments'}"
                                              style="text-decoration: underline"
                                          >
                                              Подробнее
                                          </router-link>
                                      </p>
                                  </v-expansion-panel-content>
                              </v-expansion-panel>

                              <v-expansion-panel>
                                  <v-expansion-panel-header>
                                      <span>
                                          <v-icon class="mr-2" color="deep-orange accent-4">mdi-bookmark-multiple-outline</v-icon>
                                          Лидбот по бизнесу
                                      </span>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                      <p class="mt-7">
                                          Видео-обзор лидбота по бизнесу
                                      </p>
                                      <div class="thumb-wrap">
                                          <iframe
                                              width="560"
                                              height="315"
                                              src="https://www.youtube.com/embed/YHqdtbw9Nvo"
                                              title="Обзор лидбота по бизнесу"
                                              frameborder="0"
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                              allowfullscreen
                                          >
                                          </iframe>
                                      </div>
                                      <v-expansion-panels
                                          popout
                                          focusable
                                          class="exp-panel-title mt-10"
                                      >
                                          <v-expansion-panel>
                                              <v-expansion-panel-header>
                                          <span>
                                              <v-icon class="mr-2" color="red">mdi-alert-circle</v-icon>
                                              Если видео не грузится
                                          </span>
                                              </v-expansion-panel-header>
                                              <v-expansion-panel-content>
                                                  <p class="mt-10">
                                                      Если на вашем устройстве не загружается видео с youtube, то вы можете скачать и посмотреть
                                                      видео-инструкцию с гугл-диска:
                                                      <a href="https://drive.google.com/drive/folders/1xNr_szC3_LhYkknkP6V5jSRGk2Vk7Kdu?usp=sharing">Видео-инструкции</a>
                                                  </p>
                                              </v-expansion-panel-content>
                                          </v-expansion-panel>
                                      </v-expansion-panels>
                                      <p class="mt-10">
                                          Посмотреть
                                          <a href="https://newlvl.net/leedbot/?inst=leedbot&partner=000" target="_blank">
                                              пример лидбота по бизнесу
                                          </a>
                                      </p>
                                      <p>
                                          Как получить доступ?
                                          <router-link
                                              :to="{name: 'PayInstruments'}"
                                              style="text-decoration: underline"
                                          >
                                              Подробнее
                                          </router-link>
                                      </p>
                                  </v-expansion-panel-content>
                              </v-expansion-panel>                            

                              <v-expansion-panel>
                                  <v-expansion-panel-header>
                                      <span>
                                          <v-icon class="mr-2" color="pink">mdi-bookmark-multiple-outline</v-icon>
                                          Бьюти Эликсир
                                      </span>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                      <p class="mt-7">
                                          Видео-обзор
                                      </p>
                                      <div class="thumb-wrap">
                                          <iframe
                                              width="560"
                                              height="315"
                                              src="https://www.youtube.com/embed/96MJDPsxA5g"
                                              title="Обзор Бьюти Эликсир"
                                              frameborder="0"
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                              allowfullscreen
                                          >
                                          </iframe>
                                      </div>
                                      <v-expansion-panels
                                          popout
                                          focusable
                                          class="exp-panel-title mt-10"
                                      >
                                          <v-expansion-panel>
                                              <v-expansion-panel-header>
                                          <span>
                                              <v-icon class="mr-2" color="red">mdi-alert-circle</v-icon>
                                              Если видео не грузится
                                          </span>
                                              </v-expansion-panel-header>
                                              <v-expansion-panel-content>
                                                  <p class="mt-10">
                                                      Если на вашем устройстве не загружается видео с youtube, то вы можете скачать и посмотреть
                                                      видео-инструкцию с гугл-диска:
                                                      <a href="https://drive.google.com/drive/folders/1xNr_szC3_LhYkknkP6V5jSRGk2Vk7Kdu?usp=sharing">Видео-инструкции</a>
                                                  </p>
                                              </v-expansion-panel-content>
                                          </v-expansion-panel>
                                      </v-expansion-panels>
                                      <p class="mt-10">
                                          Посмотреть
                                          <a href="https://newlvl.net/beauty-elixir/?inst=be&partner=000" target="_blank">
                                              пример инструмента
                                          </a>
                                      </p>
                                      <p>
                                          Как получить доступ?
                                          <router-link
                                              :to="{name: 'PayInstruments'}"
                                              style="text-decoration: underline"
                                          >
                                              Подробнее
                                          </router-link>
                                      </p>
                                  </v-expansion-panel-content>
                              </v-expansion-panel>

                              <v-expansion-panel>
                                  <v-expansion-panel-header>
                                      <span>
                                          <v-icon class="mr-2" color="brown">mdi-bookmark-multiple-outline</v-icon>
                                          Подарки
                                      </span>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                      <p class="mt-7">
                                          Видео-обзор
                                      </p>
                                      <div class="thumb-wrap">
                                          <iframe
                                              width="560"
                                              height="315"
                                              src="https://www.youtube.com/embed/Fa43xf9WSfE"
                                              title="Обзор Подарки"
                                              frameborder="0"
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                              allowfullscreen
                                          >
                                          </iframe>
                                      </div>
                                      <v-expansion-panels
                                          popout
                                          focusable
                                          class="exp-panel-title mt-10"
                                      >
                                          <v-expansion-panel>
                                              <v-expansion-panel-header>
                                          <span>
                                              <v-icon class="mr-2" color="red">mdi-alert-circle</v-icon>
                                              Если видео не грузится
                                          </span>
                                              </v-expansion-panel-header>
                                              <v-expansion-panel-content>
                                                  <p class="mt-10">
                                                      Если на вашем устройстве не загружается видео с youtube, то вы можете скачать и посмотреть
                                                      видео-инструкцию с гугл-диска:
                                                      <a href="https://drive.google.com/drive/folders/1xNr_szC3_LhYkknkP6V5jSRGk2Vk7Kdu?usp=sharing">Видео-инструкции</a>
                                                  </p>
                                              </v-expansion-panel-content>
                                          </v-expansion-panel>
                                      </v-expansion-panels>
                                      <p class="mt-10">
                                          Посмотреть
                                          <a href="https://newlvl.net/gifts/?inst=gifts&partner=000" target="_blank">
                                              пример инструмента
                                          </a>
                                      </p>
                                      <!-- <p class="p-price">Доступ на 3 мес: <span>500</span> руб.</p> -->
                                      <p>
                                          Как получить доступ?
                                          <router-link
                                              :to="{name: 'PayInstruments'}"
                                              style="text-decoration: underline"
                                          >
                                              Подробнее
                                          </router-link>
                                      </p>
                                  </v-expansion-panel-content>
                              </v-expansion-panel>
                          </v-expansion-panels>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <span>
                                <v-icon class="mr-2" color="teal">mdi-numeric-6-box-multiple</v-icon>
                                Доступ к инструментам
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>                            
                            <p class="mt-10">
                                <v-icon class="mr-2" color="blue">mdi-arrow-right-bold-outline</v-icon>
                                <router-link
                                    :to="{name: 'PayInstruments'}"
                                    style="font-weight: bold; font-size: 16px; color: #700000;"
                                >
                                    Получить доступ к инструментам
                                </router-link>
                            </p>
                            <p class="mb-5">
                                <v-icon class="mr-2" color="blue">mdi-arrow-down-bold-outline</v-icon>
                                <strong>Сроки доступа к инструментам</strong>:
                            </p>
                            <v-data-table
                                :headers="headersPackage"
                                :items="userPackage"
                                sort-by="instrument"
                                class="elevation-1"
                                :loading="loadingPackage"
                                loading-text="Загружается... Пожалуйста ждите"
                            >
                                <template
                                    v-slot:body="{ items }"
                                >
                                    <tbody>
                                    <tr
                                        v-for="item in items"
                                        :key="item.instrument"
                                    >   
                                        <td v-if="item.instrument === 'all'">
                                          <router-link
                                                color="red"
                                                :to="{name: 'Instruments'}"
                                            >
                                                ДОСТУП ДО
                                            </router-link>
                                        </td>
                                        <td>
                                            {{ item.paytime }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <span>
                                <v-icon class="mr-2" color="purple">mdi-numeric-7-box-multiple</v-icon>
                                Как обрабатывать заявки?
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p class="mt-7">
                                Посмотри видео "Как обрабатывать заявки"
                                <v-icon class="mr-2" color="blue">mdi-arrow-down-bold-outline</v-icon>
                            </p>
                            <div class="thumb-wrap">
                                <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/qdXyP9J6O6E"
                                    title="Как работать с заявками"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                >
                                </iframe>
                            </div>

                            <v-expansion-panels
                                popout
                                focusable
                                class="exp-panel-title mt-10"
                            >
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="red">mdi-alert-circle</v-icon>
                                            Если видео не грузится
                                        </span>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p class="mt-10">
                                            Если на вашем устройстве не загружается видео с youtube, то вы можете скачать и посмотреть
                                            видео-инструкцию с гугл-диска:
                                            <a href="https://drive.google.com/drive/folders/1xNr_szC3_LhYkknkP6V5jSRGk2Vk7Kdu?usp=sharing">Видео-инструкции</a>
                                        </p>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <span>
                                <v-icon class="mr-2" color="blue">mdi-numeric-8-box-multiple</v-icon>
                                Новые заявки (за сутки)
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p class="mt-10">
                                <v-data-table
                                    :headers="headersProspects"
                                    :items="newProspects"
                                    sort-by="name"
                                    class="elevation-1"
                                    :loading="loadingProspects"
                                    loading-text="Загружается... Пожалуйста ждите"
                                >
                                    <template
                                        v-slot:body="{ items }"
                                    >
                                        <tbody>
                                        <tr
                                            v-for="item in items"
                                            :key="item.name"
                                        >
                                            <td>
                                                <b class="font-weight-bold">{{ item.name }}</b>
                                            </td>
                                            <td>
                                                {{ item.instrument }}
                                            </td>
                                            <td>
                                                <v-icon
                                                    small
                                                    class="mr-3"
                                                    @click="showProspect(item.id)"
                                                >
                                                    mdi-account-circle
                                                </v-icon>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-data-table>
                            </p>
                            <p class="mt-10">
                                <v-icon class="mr-2" color="blue">mdi-arrow-right-bold-outline</v-icon>
                                <router-link
                                    color="success"
                                    :to="{name: 'myProspects'}"
                                >
                                    Все заявки
                                </router-link>
                            </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <span>
                                <v-icon class="mr-2" color="indigo">mdi-numeric-9-box-multiple</v-icon>
                                ВАЖНО! Лайфхаки и рекомендации
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <h2 class="mt-16 mb-10">
                                Рекомендация по размещению ссылок на инструменты newlvl в VK
                            </h2>
                            <div class="thumb-wrap">
                                <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/Vwj45WkgR1U"
                                    title="Рекомендация по размещению ссылок на инструменты newlvl в VK"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                >
                                </iframe>
                            </div>

                            <v-expansion-panels
                                popout
                                focusable
                                class="exp-panel-title mt-10"
                            >
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="red">mdi-alert-circle</v-icon>
                                            Если видео не грузится
                                        </span>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p class="mt-10">
                                            Если на вашем устройстве не загружается видео с youtube, то вы можете скачать и посмотреть
                                            видео-инструкцию с гугл-диска:
                                            <a href="https://drive.google.com/drive/folders/1xNr_szC3_LhYkknkP6V5jSRGk2Vk7Kdu?usp=sharing">Видео-инструкции</a>
                                        </p>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>

                            <p class="mt-10">
                                Сервис по укорачиванию ссылок <a href="https://clck.ru/" target="_blank">Кликер</a>
                            </p>
                            <v-divider class="mt-7 mb-7"/>

                            <h2 class="mt-16 mb-10">
                                Как написать в whatsapp без добавления номера в контакты
                            </h2>
                            <div class="thumb-wrap">
                                <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/tyXunG-UKFI"
                                    title="Как написать в whatsapp без добавления номера в контакты"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                >
                                </iframe>
                            </div>

                            <v-expansion-panels
                                popout
                                focusable
                                class="exp-panel-title mt-10"
                            >
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="red">mdi-alert-circle</v-icon>
                                            Если видео не грузится
                                        </span>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p class="mt-10">
                                            Если на вашем устройстве не загружается видео с youtube, то вы можете скачать и посмотреть
                                            видео-инструкцию с гугл-диска:
                                            <a href="https://drive.google.com/drive/folders/1xNr_szC3_LhYkknkP6V5jSRGk2Vk7Kdu?usp=sharing">Видео-инструкции</a>
                                        </p>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>

                            <v-divider class="mt-7 mb-7"/>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "Dashboard",
    data: () => ({
        dateNow: new Date().toISOString().substr(0, 10),
        headersProspects: [
            { text: 'Имя', align: 'start', value: 'name' },
            { text: 'Откуда', value: 'instrument' },
            { text: 'Действие', value: 'actions', sortable: false },
        ],
        loadingProspects: true,
        headersPackage: [
            { text: 'Инструмент', align: 'start', value: 'instrument' },
            { text: 'Доступен до', value: 'paytime' },
        ],
        loadingPackage: true
    }),
    computed: {
        ...mapGetters({
            newProspects: 'user/getNewProspects',
            userPackage: 'user/getUserPackages',
            countProspects: 'user/getCountProspects',
            profile: 'user/profile',
        }),
        profileData() {
            return this.profile
        },
        datePay() {
            let D = new Date()
            D.setDate(D.getDate() + 7)
            return D.toISOString().substr(0, 10)
        }
    },
    created () {
        this.initializeNewProspects()
        this.initializeUserPackages()
        this.initializeCountProspects()
    },
    methods: {
        ...mapActions({
            loadNewProspects: 'user/loadNewProspects',
            loadProfilePackages: 'user/loadProfilePackages',
            loadCountProspects: 'user/loadCountProspects',
        }),
        initializeNewProspects() {
            this.loadNewProspects()
                .then(() => {
                    this.loadingProspects = false
                })
        },
        initializeUserPackages() {
            this.loadProfilePackages()
                .then((response) => {
                    this.loadingPackage = false
                    this.paytimeUser = response.data[0].paytime
                })
        },
        initializeCountProspects() {
            this.loadCountProspects()
        },
        showProspect (prospectId) {
            this.$router.push({ name: 'prospectPage', params: { id: prospectId } })
        },
    },
    mounted () {
        this.loadNewProspects()
        this.loadProfilePackages()
        this.loadCountProspects()
    }
}
</script>

<style lang="sass">
p
    line-height: 26px !important

.exp-panel-title .v-expansion-panel-header
    font-size: 14px

.exp-panel-title .v-expansion-panel--active
    border: 1px solid #69db69

.exp-in-dashboard .v-expansion-panel-content__wrap
  padding: 0 5px

@media(max-width: 600px)
    .block-media-lk
        padding: 0

    .card-media-lk
        padding: 0
</style>